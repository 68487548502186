import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import NebulizadorUltrasonicoFamiliar from './nebulizador-ultrasonico-familiar.webp';
import NebulizadorDiafragma from './nebulizador-diafragma.webp';
import NebulizadorUltrasonicoProfesional from './nebulizador-ultrasonico-profesional.webp';
import AspiradorDiafragma from './aspirador-diafragma.webp';

const items = [
    { img: NebulizadorUltrasonicoFamiliar, text: "Nebulizador Ultrasonico Familiar" },
    { img: NebulizadorDiafragma, text: "Nebulizador a Diafragma" },
    { img: NebulizadorUltrasonicoProfesional, text: "Nebulizador Ultrasónico Profesional" },
    { img: AspiradorDiafragma, text: "Aspirador a Diafragma" },
  ];
  const config = {
      cardWidht: "311px",
      cardHeight: "381px",
  };
  
  const Nebulizadores = () => {
    return (
      <>
        <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
          <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
            <Spacer height="40px" />
            <ProductCards items={items} config={config} />
            <Spacer height="40px" />
          </MainContent>
        </MainGrid>
      </>
    );
  };
  
  export default Nebulizadores;