import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import TrasladosImg from "./traslados.webp";

const Traslados = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={TrasladosImg} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>
              Traslado de pacientes de baja, mediana y alta complejidad
            </Title>
          </Paragraph>
          <Paragraph>
            Nuestras ambulancias cuentan con equipamientos de última generación
            que permiten trasladar con seguridad y calidad de atención de cada
            paciente, de acuerdo a sus necesidades.
            <ul>
              <li>Traslados en ambulancia común sin medico</li>
              <li>Traslados en ambulancia común con medico</li>
              <li>
                Traslado de pacientes en Unidades Coronarias o de Terapia
                Intensiva Móvil.
              </li>
              <li>
                Contrataciones de ambulancias en todas sus modalidades, para
                cubrir todo tipo de eventos sociales, deportivos, exposiciones
                y/o cualquier otro que requiera la presencia de un móvil al
                instante.
              </li>
            </ul>
            <Title>Contamos con:</Title>
            <ul>
              <li>Móviles Polivalentes de Alta Complejidad</li>
              <li>
                Móviles para traslados de Alta, Mediana y Baja complejidad
              </li>
              <li>Automóviles para asistencia médica programada</li>
              <li>Móviles Polivalentes de Baja Complejidad</li>
              <li>Unidad Monovalente Pediátrica y Neonatal</li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Traslados;
