import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
  TextWide
} from "../../services/common";

import Abuelo from "./abuelo.webp";

const QuienesSomos = () => {
  return (
    <>
      <ImageWide src={Abuelo} />
      <MainGrid backgroundColor={({theme})=>theme.colors.dark}>
        <MainContent>
          <TextWide>
            <div>
              “Mi padre sufrió una caída muy fuerte. Con su edad, quedó
              internado por precaución en la clínica de nuestra obra social.
              Allí nos comunicaron que había que operarlo de la cadera. Mi
              marido y yo trabajamos full time y sumado a nuestros hijos, no
              disponíamos del tiempo para cuidarlo. <br /> <br />
              Sin embargo, en la clínica contaban con una enfermera muy
              capacitada que asistió a mi padre a lo largo de la internación. Le
              preguntamos donde la había contratado la clínica, y ella nos dijo
              que trabajaba para Cuidar en Casa. Una vez que a mi papá le dieron
              el alta, nos comunicamos con Cuidar en Casa, ya que, sabiendo de
              los servicios, nos quedamos tranquilos que la asistencia
              domiciliaria iba a ser muy buena para su recuperación”.
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginTop: "10px",
                textAlign: "right",
              }}
            >
              Olga, Ciudad de Buenos Aires
            </div>
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({theme})=>theme.colors.defaultBackground}>
        <MainContent backgroundColor={({theme})=>theme.colors.bright}>
          <Spacer height="20px" />
          <Title fontSize="19px">
            Internación domiciliaria brinda soluciones a aquellas personas que
            no disponen de tiempo, movilidad y dedicación para realizarlo, usted
            sólo elíjanos y su familia y/o empresa se sentirá en manos
            confiables y expertas que cuidaran de sus seres queridos y
            empleados.
          </Title>
          <Paragraph>
            La metodología aplicada en los servicios se encuentra
            particularmente estudiada, documentada, estandarizada y por tanto
            optimizada (organizamos regularmente jornadas de perfeccionamiento
            con el objetivo de avanzar hacia la calidad total) en toda la zona
            de cobertura, de manera que ante -por ejemplo- el eventual cambio de
            un profesional (sea por rotación horaria, licencias...) Ud. no
            perciba ninguna diferencia.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Opciones de Planes
            </Title>
            <ul>
              <li>Abono semanal / quincenal /mensual / anual</li>
              <li>Tarifación por turno</li>
              <li>Tarifación por hora</li>
              <li>Seguro de atención</li>
              <li>Sistema prepago por cuota mensual individual</li>
              <li>
                Sistema prepago por vouchers transferibles y no transferibles
              </li>
              <li>Planes integrales de atención</li>
            </ul>
          </Paragraph>
          <Paragraph>
            Los planes integrales prepagos de internación domiciliaria ofrecen
            soluciones personalizadas a nuestros pacientes. Los especialistas en
            cuidados especiales se encargan de la nutrición, administración de
            medicamentos con prescripción y diversas tareas de higiene. Este
            servicio es brindado de 24, 16, 12, 10, 8, 6,4 horas abonados
            mensualmente.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Venta de horas
            </Title>
            <br />
            Este sistema permite a la familia y/o responsables de los pacientes
            diagramar libremente los días y horarios según su necesidad y/
            comodidad. Internación domiciliaria adapta su cobertura de acuerdo a
            cada situación, enviando un profesional donde lo requiera:
            sanatorio, clínica, residencia geriátrica, domicilio, etc. *El
            servicio se contrata por un mínimo de 6 horas. Asociase a nuestro
            sistema internación domiciliaria de servicios.
          </Paragraph>
          <Paragraph>
            Gracias a nuestro plan de servicios GOLD, usted podrá acceder a
            nuestra amplia cobertura en servicios de salud abonando una cuota
            mensual.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Corporativo
            </Title>
            <br />
            El servicio corporativo ofrece soluciones con personal especialmente
            capacitado en cuidados especiales (acompañantes de salud,
            acompañantes gerontológicos, acompañantes terapéuticos, enfermeros
            auxiliares, enfermeros profesionales, medico,kinesiólogos,
            fonoaudiólogos, y servicio plus de cuidados paliativos) para
            Sanatorios, Clínicas, Maternidades, Geriátricos, Mutuales, Obras
            Sociales y Prepagas.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Servicios prepago de acompañante de salud
            </Title>
            <br />
            El cuidado de enfermería, el acompañamiento terapéutico y
            psicológico, la gestión de la nutrición, la administración de
            medicamentos y las medidas de higiene brindan el confort que cada
            paciente necesita para su recuperación.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Módulos:
            </Title>{" "}
            Diurnos / nocturnos/ fines de semana/ 24 horas y vacaciones. <br />
            Asistencia Geriátrica en Hogares, sanatorios, Hospitales y Clínicas.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              Personal:
            </Title>
            Asistentes Geriátricas, auxiliar de enfermería, enfermeras,
            cuidadores y acompañantes terapéuticos. <br />
            Nuestro servicio de cuidadores puede ser contratado en módulos de
            24, 16, 12, 10, 8, 6,4,1 horas, y también puede ser eventual o
            mensual.
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              En Asistencia Domiciliaria:
            </Title>
            <br />
            Creamos módulos de acuerdo a su necesidad, no deje de consultarnos.
            Si el paciente tuviera que ser derivado a un nosocomio debiendo
            quedar internado, las asistentes geriátricas se trasladan al lugar
            de internación cumpliendo las mismas horas. ​
            <Paragraph>
              El paciente nunca estará solo, las asistentes tienen prohibido
              retirarse del domicilio sin que llegue su relevo.
            </Paragraph>
            Si el paciente no está conforme con elpersonal, se realiza el cambio
            de asistentes. Se envía supervisora una vez por semana para
            constatar que los servicios brindados sean llevados correctamente
          </Paragraph>
          <Paragraph>
            <Title fontWeight="bold" fontSize="18px">
              En servicio doméstico:
            </Title>{" "}
            <br />
            Contamos con personal capacitado para desarrollar las diversas
            tareas domésticas los cuales asistirán en el horario y lugar
            establecido por el cliente.
            <Paragraph>
              En servicio en Nosocomios:
              <ul>
                <li>
                  Contáctenos con 12 horas de anticipación, para coordinar el
                  servicio. <br />
                  Los módulos son 24, 16, 12, 10, 8, 6, 4.
                  <br />
                  - de 24 horas <br />
                  - 12 horas nocturnas <br />
                  - 12 diurnas <br />
                </li>
                <li>las horas del relevo lo dispone la familia</li>
              </ul>
            </Paragraph>
          </Paragraph>{" "}
          <br />
          <Paragraph>
            <h1>Planes</h1>
            Le ofrece tres planes accesibles, adaptados a su necesidad, que
            combinan enfermería domiciliaria y atención médica. Además, ¡son
            transferibles!*
          </Paragraph>
          <Paragraph>
            <Title>Plan Gabriel 1000</Title>
            <br />
            <ol>
              <li>
                Servicio prestación de enfermería: 4 visitas mensuales. 6-8
              </li>
              <li>Servicio médico: 2 visitas mensuales. 4-6</li>
              <li>Servicio de kinesiología: 4 sesiones mensuales.6-8</li>
              <li>
                Servicio de traslado de ambulancia UTIM: 2 traslados mensuales
                al nosocomio más cercano.4-6
                <ol type="a">
                  <li>
                    Servicio de enfermería. Incluye: curaciones, inyectables e
                    higiene y confort.
                  </li>
                  <li>Para colocación de vía consultar precios.</li>
                  <li>
                    *Titular inscripto podrá utilizar el servicio para cualquier
                    persona que él autorice, pero deberá dejar constancia con su
                    ­firma.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
          <Paragraph>
            <Title>Plan Milagros 1500</Title>
            <br />
            <ol>
              <li>Servicio prestación de enfermería: 6visitas mensuales. -8</li>
              <li>Servicio médico: 4 visitas mensuales. -6</li>
              <li>Servicio de kinesiología: 6 sesiones mensuales.-8</li>
              <li>
                Servicio de traslado de ambulancia UTIM: 4 traslados mensuales
                al nosocomio más cercano.-6
                <ol type="a">
                  <li>
                    Servicio de enfermería. Incluye: curaciones, inyectables e
                    higiene y confort.
                  </li>
                  <li>Para colocación de vía consultar precios.</li>
                  <li>
                    *Titular inscripto podrá utilizar el servicio para cualquier
                    persona que él autorice, pero deberá dejar constancia con su
                    ­firma.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
          <Paragraph>
            <Title>Plan Milena 2000</Title>
            <br />
            <ol>
              <li>Servicio prestación de enfermería: 8 visitas mensuales.</li>
              <li>Servicio médico: 6 visitas mensuales.</li>
              <li>Servicio de kinesiología: 8 sesiones mensuales.</li>
              <li>
                Servicio de traslado de ambulancia UTIM: 6 traslados mensuales
                al nosocomio más cercano.
                <ol type="a">
                  <li>
                    Servicio de enfermería. Incluye: curaciones, inyectables e
                    higiene y confort.
                  </li>
                  <li>Para colocación de vía consultar precios.</li>
                  <li>
                    *Titular inscripto podrá utilizar el servicio para cualquier
                    persona que él autorice, pero deberá dejar constancia con su
                    ­firma.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
          <Spacer height="20px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default QuienesSomos;
