import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Inyectables from "./inyectables.webp";

const InyectablesCuraciones = () => {
  return (
    <>
      <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Inyectables} />
        </MainContent>
        <MainContent backgroundColor={({theme}) => theme.colors.bright}>
          <Paragraph>
            <Title>Inyectables y curaciones</Title>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Servicios eventuales.</Title>
            <br />A través de este servicio, buscamos ofrecer una posibilidad a
            aquellos que no requieren cuidados de tiempo completo o por jornada,
            pero que sí necesitan un servicio eventual como la aplicación de
            inyecciones, curaciones, control de presión arterial y prestaciones
            similares. Esto permite confeccionar un presupuesto a medida de la
            necesidad de la persona a cuidar y de su familia.
          </Paragraph>
          <Paragraph>
            Contamos con profesionales especializados y matriculados en diversas
            áreas médicas. Para los servicios de kinesiología se deberá indicar
            cuál es el área específica o dónde se registra el dolor y, si es
            posible, los datos de contacto con su traumatólogo. También deberá
            brindar datos relevantes de la salud del paciente: enfermedades
            anteriores, complicaciones, alergias o sensibilidad de algún tipo a
            medicamentos, enfermedades crónicas, otros traumatismos, hábitos o
            costumbres que puedan relacionarse con su salud. Estos datos
            contribuyen a que la atención sea de mejor calidad.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Algunos de nuestros servicios eventuales incluyen:</Title>
            <ul>
              <li>Inyectables en domicilios, hoteles, etc.</li>
              <li>Control de tensión arterial.</li>
              <li>Control de glucemia.</li>
              <li>Curaciones.</li>
              <li>Enemas.</li>
            </ul>
          </Paragraph>
          <Paragraph>
            Trabajo por horas de 1 a 3 horas <br />
            Rehabilitación asistida. <br />
            Servicio de higiene y confort permanente. <br />
            Aseo personal con dependencia o sin dependencia. <br />
            Servicio gastronómico (se elaboran las cuatro comidas en su hogar).{" "}
            <br />
            Servicio de lavandería (carga de lavarropas y tendido). <br />
            Servicio de enfermería (control de signos vitales). <br />
            Servicio de mucama (cambio y tendido de sabanas y/o acolchados).{" "}
            <br />
            Servicio de control, armado y suministro de medicación. <br />
            Curaciones. <br />
            Cuidados intensivos. (Enfermeras) <br />
            Servicio Doméstico (mantenimiento del hogar). <br />
            Alimentación. <br />
            Dama de compañía <br />
            Realizamos campañas de vacunación en Capital Federal y Gran Buenos
            aires. <br />
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default InyectablesCuraciones;
