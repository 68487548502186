import React from "react";
import styled from "styled-components";

const Hero = (props) => {
  return (
    <Wrapper backgroundColor={props.backgroundColor}>
      <HeroImg image={props.image} />
      <HeroText fontStyle={props.fontStyle}>{props.children}</HeroText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background-color: ${({backgroundColor}) => backgroundColor || 'none'};

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    grid-template-columns: 2fr 3fr;
  }
`;

const HeroImg = styled.div`
    grid-row: 2;
    width: 100%; 
    min-height: 200px;
    background-image: url('${({ image }) => image}');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    @media ${({ theme }) => theme.mediaQueries["desktop"]} {
      grid-row: 1;
      min-height: 400px;
      background-position: center;
    }
`;

const HeroText = styled.div`
  margin: auto 10%;
  padding: 40px;
  text-align: right;

  font-size: 20px;
  font-style: ${({fontStyle}) => fontStyle || 'italic'};
  line-height: 1.6em;
  @media ${({theme}) => theme.mediaQueries["desktop"]}{
    margin: auto 20%;
  }
`;

export default Hero;
