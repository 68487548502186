import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import AndAluminio from './andador-aluminio.webp';
import AndRuedas from './andador-ruedas.webp';
import AndTijera from './andador-tijera.webp';
import AndTijeraRuedas from './andador-tijera-ruedas.webp';

const items = [
    { img: AndAluminio, text: "Andador de Aluminio" },
    { img: AndRuedas, text: "Andador con Ruedas" },
    { img: AndTijera, text: "Andador Tijera" },
    { img: AndTijeraRuedas, text: "Andador Tijera con Ruedas" },
  ];
  const config = {
      cardWidht: "311px",
      cardHeight: "381px",
  };
  
  const Andadores = () => {
    return (
      <>
        <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
          <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
            <Spacer height="40px" />
            <ProductCards items={items} config={config} />
            <Spacer height="40px" />
          </MainContent>
        </MainGrid>
      </>
    );
  };
  
  export default Andadores;