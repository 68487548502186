import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import AspiradorSecreciones from './aspirador-secreciones.webp';
import Bipap from './bipap.webp';
import CalentadorHumidificador from './calentador-humidificador.webp';
import ConcentradorO2 from './concentrador-o2.webp';
import ConcentradorO2Portatil from './concentrador-o2-portatil.webp';
import MochilaOxigeno from './mochila-oxigeno-gaseoso.webp';
import Cpap from './cpap.webp';
import OximetroPulso from './oximetro-pulso.webp';
import SoporteVentilatorioQuantum from './soporte-ventilatorio-quantum-psv.webp';

const items = [
    { img: AspiradorSecreciones, text: "Aspirador de Secreciones" },
    { img: Bipap, text: "Bipap" },
    { img: CalentadorHumidificador, text: "Calentador Humidificador" },
    { img: ConcentradorO2, text: "Concentrador de O2" },
    { img: ConcentradorO2Portatil, text: "Concentrador de O2 Portatil" },
    { img: MochilaOxigeno, text: "Mochila de Oxigeno Gaseoso" },
    { img: Cpap, text: "CPAP" },
    { img: OximetroPulso, text: "Oximetro de Pulso" },
    { img: SoporteVentilatorioQuantum, text: "Soporte Ventilatorio Quantum PSV" },
  ];
  const config = {
      cardWidht: "311px",
      cardHeight: "381px",
  };
  
  const EquiposOxigeno = () => {
    return (
      <>
        <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
          <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
            <Spacer height="40px" />
            <ProductCards items={items} config={config} />
            <Spacer height="40px" />
          </MainContent>
        </MainGrid>
      </>
    );
  };
  
  export default EquiposOxigeno;