import React from "react";

import InstitBar from "./institBar/InstitBar";
import LogoBar from "./logoBar/LogoBar";

const Header = () => {
  return (
    <>
      <InstitBar />
      <LogoBar />
    </>
  );
};

export default Header;

