import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MainGrid, MainContent, OnlyDesktop } from "../../../services/common";

import Envelope from "./envelope.webp";

const InstitBar = () => {
  return (
    <Border>
      <MainGrid>
        <Wrapper>
          <OnlyDesktop>
            <Email>
              <ImgEnvelope src={Envelope} alt="" />
              <a href={`mailto:${window.siteConfig.CONTACT_DATA.EMAIL}`}>
                {window.siteConfig.CONTACT_DATA.EMAIL}
              </a>
            </Email>
          </OnlyDesktop>
          <OnlyDesktop>
            <a href="http://www.cuidarencasa.com.ar">www.cuidarencasa.com.ar</a>
          </OnlyDesktop>
          <Link to="/nosotros.html">Acerca de Nosotros</Link>
          <Link to="/">Sumate a nuestro equipo</Link>
        </Wrapper>
      </MainGrid>
    </Border>
  );
};

const Border = styled.div`
  border-bottom: 1px solid gray;
`;

// inherits the use of the center column in grid
const Wrapper = styled(MainContent)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`;

const Email = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ImgEnvelope = styled.img`
  margin-right: 5px;
`;

export default InstitBar;
