import React, { Component } from "react";
import styled from "styled-components";
import ReactModalBuilder from "./ReactModalBuilder";

class ReactMenuModal extends Component {
  render() {
    const { menuItems } = this.props;

    return (
      <Background>
        <Wrapper>
          {menuItems.map((item) => {
            return (
              <MenuItem key={Math.random()} href={item.link}>
                {item.name}
              </MenuItem>
            );
          })}
        </Wrapper>
        <ReactModalBuilder menuItems={menuItems}/>
      </Background>
    );
  }
}

const Background = styled.div`
  background: ${({ theme }) => theme.colors.menuBackground};
  color: ${({ theme }) => theme.colors.primary};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const MenuItem = styled.a`
  flex-grow: 1;
  padding: 12px;

  text-align: center;
  transition: all 500ms;

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.bright};
    background: ${({ theme }) => theme.colors.grayBackground};
  }
`;

export default ReactMenuModal;
