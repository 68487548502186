import React from "react";

import Hero from "../../services/components/hero/Hero";
import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide
} from "../../services/common";

import Baston from "./baston.webp";
import Abuelo from "./abuelo.webp";
import Abuela from "./abuela.webp";

const Home = () => {
  return (
    <>
      <Hero image={Baston} backgroundColor={"khaki"}>
        “Ana Juárez estuvo junto a mi madre 8 meses. Ella necesitaba un vendaje
        especial en las piernas que debía ser reemplazado y desinfectado
        periódicamente, además de controlar el azúcar en su sangre y
        administrarle los medicamentos para la diabetes y el Alzheimer. Al poco
        tiempo de tenerla con nosotros, mi madre sanó de las piernas y la
        diabetes fue controlada, mediante el cuidado de profesional que la
        acompañante de Cuidar en Casa nos brindó”.
        <div style={{ fontWeight: "bold", marginTop: "10px" }}>
          Liliana Fernández, Devoto Ciudad de Buenos Aires
        </div>
      </Hero>
      <MainGrid backgroundColor={({theme})=>theme.colors.defaultBackground}>
        <MainContent backgroundColor={({theme})=>theme.colors.bright}>
          <Spacer height="30px" />
          <Title>¿Qué es la internación domiciliaria?</Title>
          <Paragraph>
            La modalidad de Internación Domiciliaria (INTERNACION DOMICILIARIA)
            fue inaugurada en 1988 en argentina, buenos aires, por el Dr
            .Christian Meier, como una extensión del hospital hacia el domicilio
            del paciente. Las razones para crear esta primera unidad de
            INTERNACION DOMICILIARIA en el mundo eran descongestionar las salas
            hospitalarias y brindar a los pacientes un ambiente más humano, y
            favorable para su recuperación.
          </Paragraph>
          <Paragraph>
            La internación hospitalaria no es la panacea para el paciente y la
            familia. Suele ser estresantTe e y agotadora tanto física como
            emocionalmente. Su costo para el sistema de salud es muy alto,
            existiendo una tendencia mundial a dejar las camas hospitalarias
            para la patología aguda descompensada. La INTERNACION DOMICILIARIA
            es una respuesta concreta a las necesidades de atención de la salud
            de las personas. Una alternativa asistencial pensando en la salud
            desde un punto de vista integral, ligado a valores como la
            convivencia familiar, entorno, afecto y responsabilidad
            participativa y conjunta no exclusivamente médica.
          </Paragraph>
          <Paragraph>
            Es una práctica que apunta a estar más cerca de los ciudadanos que
            necesitan ser atendidos, una concepción moderna que combina la
            eficiencia de un equipo de salud interdisciplinario con recursos
            técnicos de actualidad para lograr así una prestación ambulatoria
            cuyo papel fundamental es resguardar la autonomía y calidad de vida
            del paciente.
          </Paragraph>
          <Spacer height="30px" />
        </MainContent>
      </MainGrid>
      <ImageWide src={Abuelo} alt="" />
      <MainGrid backgroundColor={({theme})=>theme.colors.defaultBackground}>
        <MainContent backgroundColor={({theme})=>theme.colors.bright}>
          <Spacer height="30px" />
          <Title fontSize="22px">Internación domiciliaria</Title>
          <Paragraph>
            La unidad de Cuidados Domiciliarios o Internación Domiciliaria tiene
            como objetivo principal, y entre muchos otros beneficios, evitar las
            internaciones prolongadas en Clínicas, Sanatorios y Hospitales
            previniendo muchas complicaciones.
          </Paragraph>
          <Title fontSize="16px" fontWeight="bold">
            Principales beneficios de la internación domiciliaria:
          </Title>
          <Paragraph>
            <ul>
              <li>
                Permite al paciente bene­ficiarse de la comodidad del hogar y de
                la compañía de su entorno familiar.
              </li>
              <li>
                Reduce el riesgo de contraer infecciones intrahospitalarias.
              </li>
              <li>
                Exime a la familia de tener que desplazarse al hospital y, al
                permitirles manejar sus tiempos, de noches de cuidados.
              </li>
              <li>
                Disminuye la ansiedad y el estrés que el hospital provoca a los
                pacientes.
              </li>
            </ul>
          </Paragraph>
          <Title fontSize="22px">
            Algunos motivos por los que se puede acudir a una internación
            domiciliaria
          </Title>
          <Paragraph>
            <ul>
              <li>
                Pacientes con insuficiencia respiratoria grave de causa
                neurológica central, espinal y/o muscular que requieran
                asistencia respiratoria mecánica sin potencial de recuperación
                de la función.
              </li>
              <li>
                Pacientes con secuelas graves de enfermedades neurológicas que
                requieran control y mantenimiento de funciones vitales, con y
                sin asistencia respiratoria mecánica.
              </li>
              <li>
                Enfermedades traumatológicas y/o de los músculos que por la
                complejidad de sus secuelas necesiten cuidados y asistencia
                médica y de enfermería intensivas en domicilio.
              </li>
              <li>
                Postquirúrgicos o pacientes con heridas abiertas hasta lograr la
                recuperación total de los tejidos.
              </li>
              <li>
                Enfermedades oncológicas en recuperación, en estadios terminales
                o en situación de cuidados paliativos.
              </li>
              <li>HIV en estadios terminales.</li>
              <li>Tratamiento del dolor.</li>
            </ul>
            Si lamentablemente el final de vida estuviera próximo, redoblamos nuestros esfuerzos en todo sentido; acompañamos en todo momento tanto al paciente como a Ud. y su familia, para hacer lo inevitable más llevadero.
          </Paragraph>
          <Paragraph>Ofrecemos asistencia activa y total por parte de un equipo interdisciplinario especializado tanto a los pacientes como a los familiares.</Paragraph>
          <Title fontSize="22px">Estos cuidados tienen entre sus objetivos</Title>
          <Paragraph>
            <ul>
            <li>Controlar los síntomas y hacer más llevadero el proceso por el cual pasan los pacientes, su familia y entorno afectivo.</li>
            <li>Detectar, evaluar y paliar las necesidades físicas, psicológicas, sociales y espirituales.</li>
            <li>Promover e implementar estrategias de cuidado destinadas a proporcionar bienestar y mejorar la calidad de fin de vida</li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
      <ImageWide src={Abuela} alt="" />
      <MainGrid backgroundColor={({theme})=>theme.colors.defaultBackground}>
        <MainContent backgroundColor={({theme})=>theme.colors.bright}>
          <Spacer height="30px" />
          <Title>Acerca de nuestros cuidados</Title>
          <Paragraph>
            <Title fontSize="22px">Profesionalismo</Title><br/>
            Contamos con un equipo interdisciplinario altamente capacitado, el cual se reúne y establece un consenso para definir una lista de prioridades, problemas y necesidades para poder definir las estrategias de intervención de cada área.
          </Paragraph>
          <Paragraph>
            <Title fontSize="22px">Integridad</Title><br/>
            Trabajamos promoviendo el bienestar y la autonomía de los pacientes y de sus familiares, con enfermedades graves, progresivas proporcionándoles cuidados de manera global y activa, tanto de los aspectos físicos, emocionales, psicosociales y espirituales.
          </Paragraph>
          <Paragraph>
            <Title fontSize="22px">Objetivos claros</Title><br/>
            Apuntamos a controlar los síntomas, dar apoyo emocional y comunicacional en el domicilio del paciente, preservando el ambiente familiar.
          </Paragraph>
          <Spacer height="20px" />
          <Title textAlign="center">Nuestros cuidados no se proponen prolongar ni acortar la vida, sino mejorar su calidad.</Title>
          <Spacer height="20px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Home;
