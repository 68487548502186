import styled, { css } from "styled-components";

export const OnlyDesktop = styled.div`
  display: none;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    display: initial;
  }
`;

export const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;

  background-color: ${({backgroundColor}) => backgroundColor};
`;

export const MainContent = styled.div`
  grid-column: 2;
  background-color: ${({backgroundColor}) => backgroundColor};
  padding: 0 20px;

  /* return the css block only if the paramether is set*/
  ${({noPadding}) => 
    noPadding && css`
      padding: 0 0;
    `}
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.titlesBig};
  font-size: ${({ fontSize }) => fontSize || "25px"};
  line-height: 1.4em;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ fontWeight }) => fontWeight};

  /* return the css block only if the paramether is set*/
  ${({ textAlign }) =>
    textAlign && css`
      display: block;
      text-align: ${({ textAlign }) => textAlign};
    `}
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.fonts.paragraph};
  font-size: 16px;
  line-height: 1.5em;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Spacer = styled.div`
  height: ${({ height }) => height};
`;

export const ImageWide = styled.div`
  background-image: url(${({src}) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${({backgroundPosition}) => backgroundPosition || "center"};

  width: 100%;
  height: 200px;

  @media ${({theme}) => theme.mediaQueries['desktop']}{
    height: 300px;
  }
`;

export const TextWide = styled.div`
  color: ${({theme})=>theme.colors.bright};
  padding: 20px 0;
  font-size: 19px;
  font-style: italic;
`;