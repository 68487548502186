import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./index.scss";

import Header from "./sections/header/Header";
import Menu from "./sections/menu/Menu";
import Footer from "./sections/footer/Footer";

import Home from './pages/home/Home';
import QuienesSomos from './pages/quienes-somos/QuienesSomos';
import ServiciosProfesionales from './pages/servicios-profesionales/ServiciosProfesionales';
import MedicoDomiciliario from './pages/medico-domiciliario/MedicoDomiciliario';
import InyectablesCuraciones from './pages/inyectables-curaciones/InyectablesCuraciones';
import EnfermeriaProfesional from './pages/enfermeria-profesional/EnfermeriaProfesional';
import Cuidadoras from './pages/cuidadoras/Cuidadoras';
import KinesiologiaDomicilio from './pages/kinesiologia-a-domicilio/KinesiologiaDomicilio';
import RadiologiaDomicilio from './pages/radiologia-a-domicilio/RadiologiaDomicilio';
import Fonoaudiologia from './pages/fonoaudiologia/Fonoaudiologia';
import AcompanianteTerapeutico from './pages/acompaniante-terapeutico/AcompanianteTerapeutico';
import AsistentesGerontologicos from './pages/asistentes-gerontologicos/AsistentesGerontologicos';
import CuidadosPaliativos from './pages/cuidados-paliativos/CuidadosPaliativos';
import Traslados from './pages/traslados/Traslados';
import PersonalDomestico from './pages/personal-domestico/PersonalDomestico';
import Rentals from './pages/rentals/Rentals';
import CamasOrtopedicas from './pages/camas-ortopedicas/CamasOrtopedicas';
import SillasRuedas from './pages/sillas-ruedas/SillasRuedas';
import Andadores from './pages/andadores/Andadores';
import MuletasBastones from "./pages/muletas-bastones/MuletasBastones";
import Nebulizadores from "./pages/nebulizadores/Nebulizadores";
import EquiposOxigeno from "./pages/equipos-oxigeno/EquiposOxigeno";
import Contactenos from "./pages/contactenos/Contactenos";
import Nosotros from "./pages/nosotros/Nosotros";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Menu />
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <Route path='/quienes-somos.html' component={QuienesSomos}></Route>
          <Route path='/servicios-profesionales.html' component={ServiciosProfesionales}></Route>
          <Route path='/medico-domiciliario.html' component={MedicoDomiciliario}></Route>
          <Route path='/enfermeria-profesional.html' component={EnfermeriaProfesional}></Route>
          <Route path='/inyectables-curaciones.html' component={InyectablesCuraciones}></Route>
          <Route path='/cuidadoras.html' component={Cuidadoras}></Route>
          <Route path='/kinesiologia-a-domicilio.html' component={KinesiologiaDomicilio}></Route>
          <Route path='/radiologia-a-domicilio.html' component={RadiologiaDomicilio}></Route>
          <Route path='/fonoaudiologia.html' component={Fonoaudiologia}></Route>
          <Route path='/acompanante-terapeutico.html' component={AcompanianteTerapeutico}></Route>
          <Route path='/asistentes-gerontologicos.html' component={AsistentesGerontologicos}></Route>
          <Route path='/cuidados-paliativos.html' component={CuidadosPaliativos}></Route>
          <Route path='/traslados.html' component={Traslados}></Route>
          <Route path='/personal-domestico.html' component={PersonalDomestico}></Route>
          <Route path='/rentals.html' component={Rentals}></Route>
          <Route path='/camas-ortopedicas.html' component={CamasOrtopedicas}></Route>
          <Route path='/sillas-ruedas.html' component={SillasRuedas}></Route>
          <Route path='/andadores.html' component={Andadores}></Route>
          <Route path='/muletas-bastones.html' component={MuletasBastones}></Route>
          <Route path='/nebulizadores.html' component={Nebulizadores}></Route>
          <Route path='/equipos-oxigeno.html' component={EquiposOxigeno}></Route>
          <Route path='/contactenos.html' component={Contactenos}></Route>
          <Route path='/nosotros.html' component={Nosotros}></Route>
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
