import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Equipos from "./equipos.webp";

const Rentals = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Equipos} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.grayBackground}>
        <MainContent>
          <TextWide>
            Le ofrecemos acceder a todo el equipamiento que necesite para su
            rehabilitación y tratamiento sin moverse de su domicilio, con sólo
            una llamada telefónica.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            <Title>Forma de contratación y entrega</Title>
          </Paragraph>
          <Paragraph>
            ALQUILAR <br />
            Los productos que ofrecemos pueden ser alquilados por el tiempo que
            el paciente requiera. Pudiendo de esta manera tener un post
            operatorio cómodo en su domicilio (camas eléctricas, soporte de
            suero, etc.) o realizar rehabilitación con ayudas para el movimiento
            (andadores, muletas) por el tiempo que sea necesario. Todos los
            productos se entregan en óptimas condi-ciones de calidad e higiene.
          </Paragraph>
          <Paragraph>
            Requisitos para el alquiler:
            <ul>
              <li>Presentar el DNI (Documento nacional de identidad)</li>
              <li>
                y un impuesto de luz, gas o teléfono que pueda rectificar nombre
                y domicilio actual de la persona que se acerque al local para
                efectuar el alquiler
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            ALQUILAR A DOMICILIO <br />
            El alquiler a domicilio es un servicio puerta a puerta que puedan
            acceder telefónica-mente. Los requisitos son, presentar fotocopia
            del DNI (Documento nacional de identidad) y fotocopia de un impuesto
            de luz, gas o teléfono que pueda rectificar el nombre y domicilio
            actual de la persona efectué el alquiler. Los costos de envío serán
            informados según la zona destinataria.
          </Paragraph>
          <Paragraph>
            COMPRAR <br />
            La compra de los elementos ortopédicos puede ser realizada en el
            local o telefónicamente con envíos a domicilio.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

const TextWide = styled.div`
  color: ${({ theme }) => theme.colors.bright};
  padding: 30px 0;
  font-size: 30px;
`;

export default Rentals;
