import React, { Component } from "react";
import styled from "styled-components";

class ReactModalBuilder extends Component {
  render() {
    const { menuItems } = this.props;
    const modalMenuItems = menuItems.filter(
      (item) =>
        item.type === "modal" && item.hasOwnProperty("modalLinks") === true
    );

    if (modalMenuItems.length === 0) return <> </>;

    return modalMenuItems.map((item) => {
      const generatedId = item.link.replace(/[#\s]/g, "");
      return (
        <ModalWrapper id={generatedId} key={Math.random()}>
          <ModalBox>
            <ModalClose>
              <a href="#">X</a>
            </ModalClose>
            {
              // console.log(item)
              item.modalLinks.map((modalItem) => {
                console.log();
                return (
                  <ModalItemWrapper key={Math.random()}>
                    <ModalItem href={modalItem.link}>
                      {modalItem.name}
                    </ModalItem>
                  </ModalItemWrapper>
                );
              })
            }
          </ModalBox>
        </ModalWrapper>
      );
    });
  }
}
const ModalWrapper = styled.div`
  z-index: 998;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);

  pointer-events: none;
  opacity: 0;
  visibility: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.4s;

  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
`;

const ModalBox = styled.ul`
  z-index: 999;
  width: 80%;
  height: fit-content;
  top: 25vh;
  list-style: none;
  padding: 20px;
  /* padding-top: 50px; */

  border-radius: 10px;
  background-color: white;

  display: flex;
  flex-direction: column;
`;

const ModalItemWrapper = styled.li`
  flex: 1;
  background-color: lightgray;
  margin: 5px 0;
  padding: 10px;
  text-align: center;

  &:hover {
    background-color: #14767d;
    transition: 1s;
    a {
      transition: 0.4s;
      color: white;
    }
  }
`;
const ModalClose = styled.li`
  margin-left: auto;
  width: 25px;
  height: 25px;
  padding: 5px;
  background-color: #ff6161;
  border: 1 solid gray;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  a {
    text-decoration: none;
    font-family: "Arial", sans-serif;
    font-size: 0.95rem;
    line-height: 1.4rem;
    font-weight: bold;
    text-align: center;
    color: white;
  }
`;

const ModalItem = styled.a`
  text-decoration: none;
  color: black;
`;

export default ReactModalBuilder;
