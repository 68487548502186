import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Cuidados from "./cuidados.webp";

const CuidadosPaliativos = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Cuidados} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Equipos especiales en cuidados paliativos</Title>
            <br />
            <Title fontSize="20px">
              Programa atención domiciliaria / Cuidados Paliativos
            </Title>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">¿Que son los cuidados paliativos?</Title>
            <br />
            Según la definición original (Organización Mundial de la Salud,
            1984) los cuidados paliativos consisten en el cuidado activo total
            para aquellos pacientes cuya enfermedad no responde a tratamientos
            curativos. <br />
            Incluyen el control del dolor, de otros síntomas físicos y de los
            aspectos psicológicos, espirituales y sociales.
          </Paragraph>
          <Paragraph>
            El objetivo de los cuidados paliativos es lograr la mejor calidad de
            vida posible para el paciente y su familia. Muchos aspectos de los
            cuidados paliativos son aplicables tempranamente en el curso de la
            enfermedad aun cuando todavía sea pertinente algún tratamiento con
            el objetivo de prolongar la sobrevida o mejorar la calidad de vida.
            La necesidad del cuidado especializado puede surgir en cualquier
            momento de la enfermedad.
          </Paragraph>
          <Paragraph>
            Los cuidados paliativos:
            <ul>
              <li>
                Afirman la vida y consideran el morir como un proceso natural.
              </li>
              <li>No acortan ni prolongan la vida.</li>
              <li>Proveen alivio al dolor y a otros síntomas.</li>
              <li>
                Integran los aspectos espirituales y sociales al cuidado de los
                pacientes.
              </li>
              <li>
                Ofrecen un sistema de apoyo para que el paciente viva lo más
                activamente posible hasta su muerte.
              </li>
              <li>
                Ofrecen un sistema de apoyo para ayudar a la familia a afrontar
                la enfermedad del paciente.
              </li>
            </ul>
            Es importante destacar que la unidad de cuidado es la familia y no
            sólo el paciente. La familia participa activamente del cuidado y el
            equipo especializado da soporte al entorno tanto como al paciente.
          </Paragraph>
          <Paragraph>
            <b>Los cuidados paliativos requieren:</b>
            <ul>
              <li>Enfoque multidisciplinario de evaluación y tratamiento.</li>
              <li>
                Evaluación permanente de los cambios como base para la toma de
                decisiones.
              </li>
              <li>Toma de decisiones racionales basadas en la evidencia.</li>
              <li>Tratamiento del duelo.</li>
              <li>Seguimiento de la familia.</li>
            </ul>
            Cuidar en Casa cuenta con un equipo especializado en cuidados
            paliativos conformado por:
            <ul>
              <li>Un médico especialista en dolor.</li>
              <li>
                Cuidadoras a domicilio, sanatorios, hospitales, clínicas,
                geriátricos
              </li>
              <li>Acompañantes Terapéuticos</li>
              <li>Auxiliares de Enfermería</li>
              <li>Enfermeros Profesionales</li>
              <li>Kinesiólogos</li>
              <li>Fonoaudiólogos</li>
              <li>Nutricionistas</li>
              <li>Terapista ocupacional</li>
              <li>Psicólogo</li>
              <li>Psiquiatra</li>
              <li>Manicura</li>
              <li>Limpieza</li>
              <li>Traslados a domicilio, sanatorios, clínica</li>
              <li>Alquiler y venta ortopedia, etc</li>
            </ul>
            Estos profesionales combinan su experiencia y trabajan en conjunto
            para cubrir de manera integral las necesidades de cada paciente.
          </Paragraph>
          <Paragraph>
            Si lamentablemente el final de vida estuviera próximo, redoblamos
            nuestros esfuerzos en todo sentido; acompañamos en todo momento
            tanto al paciente como a Ud. y su familia, para hacer lo inevitable
            más llevadero. <br />
            Ofrecemos asistencia activa y total por parte de un equipo
            interdisciplinario especializado tanto a los pacientes como a los
            familiares.
          </Paragraph>
          <Paragraph>
            Estos cuidados tienen entre sus objetivos:
            <ul>
              <li>
                Controlar los síntomas y hacer más llevadero el proceso por el
                cual pasan los pacientes, su familia y entorno afectivo.
              </li>
              <li>
                Detectar, evaluar y paliar las necesidades físicas,
                psicológicas, sociales y espirituales.
              </li>
              <li>
                Promover e implementar estrategias de cuidado destinadas a
                proporcionar bienestar y mejorar la calidad de fin de vida
              </li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default CuidadosPaliativos;
