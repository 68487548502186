import React from "react";
import styled from "styled-components";

// import ContactCard from "./ContactCard/ContactCard";
import FullContactCard from "./FullContactCard/FullContactCArd";
import LogoImg from "./logo.webp";

const LogoBar = () => {
  return (
    <LogoBarWrapper>
      <a href="/">
        <Logo src={LogoImg} />
      </a>
      <FullContactCard />
    </LogoBarWrapper>
  );
};

const LogoBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 18px;
  border: 0px;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Logo = styled.img`
  width: 150px;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    width: 200px;
  }
`;

export default LogoBar;
