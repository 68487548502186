import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import AbuelaActiva from "./abuela-activa.webp";

const Nosotros = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={AbuelaActiva} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            Cuidar en Casa es una empresa que se caracteriza para el compromiso
            y eficiencia en atención y cuidado del paciente, tratando de
            enriquecer el desarrollo y crecimiento de los profesionales.
          </Paragraph>
          <Paragraph>
            Una organización que está allí -a veces durante los peores momentos
            de su vida- como un aliado, para garantizar la seguridad y la paz de
            la mente. Somos capaces de hacer esto por el compromiso y la pasión
            de nuestro personal. Es lo que somos. Todo lo que hacemos en Cuidar
            en Casa, es guiado por un propósito central claro. Apoyando este
            objetivo son cuatro valores esenciales los que impulsan nuestras
            decisiones de cada día:
          </Paragraph>
          <Paragraph>
            Somos un servicio integral de asistencia y cuidado domiciliario de
            pacientes enfocado no solo en la salud, sino también en la
            contención y en el asesoramiento de familiares y personas cercanas.
          </Paragraph>
          <Paragraph>
            Cuidar en Casa pone a disposición profesionales especializados y
            matriculados en medicina, enfermería, radiología, kinesiología,
            nutrición, entre otros. Tranquilidad y seguridad: módulos de
            enfermería y cuidadores a domicilio de 4,6, 8, 10,16, 12 y 24 horas.
            Además: traslados de pacientes, atención médica domiciliaria,
            alquiler de ortopedia, curaciones. También en hospitales y
            geriátricos.
          </Paragraph>
          <Paragraph>
            Cuidar en Casa privilegia la preservación de la salud ante que las
            acciones curativas, brinda la tranquilidad, privacidad y atención
            personalizada que Ud., su familia y su empresa necesita.
            <ul>
              <li>INTEGRIDAD, siempre hacer lo correcto.</li>
              <li>RESPETO, todo el mundo lo merece.</li>
              <li>
                COMPROMISO, nos preocupamos profundamente por no decepcionar a
                nadie.
              </li>
              <li>CUIDADO, ponemos nuestro corazón en el trabajo.</li>
            </ul>
            Solicite una entrevista para evaluar juntos la ventaja de contar con
            un equipo altamente profesional, sustentado en la eficiencia y la
            ética, a una relación costo beneficio sumamente competitiva, incluso
            sobre la posibilidad de contar con personal propio. En tal sentido
            ya no tendrá que preocuparse por ausencias, vacaciones capacitación,
            actualización, riesgos laborales; pudiendo flexibilizar la dotación
            convenientemente acorde a su real necesidad de demanda en cada
            momento.
          </Paragraph>
          <Paragraph>
            <b>Nuestros servicios se encuentran dirigidos a:</b>
          </Paragraph>
          <Paragraph>
            <b>Servicios corporativos</b>
            <ul>
              <li>
                Provisión de recursos humanos en todas las especialidades
                sanitarias dirigidos a:
              </li>
              <li>Clínicas y sanatorios</li>
              <li>Geriátricos</li>
              <li>Prepagas</li>
              <li>Obras sociales</li>
              <li>Compañías de seguros</li>
              <li>Empresas privadas</li>
              <li>Empresas públicas</li>
              <li>ONG´S</li>
              <li>Instituciones educativas</li>
              <li>(Escuelas, jardines de infantes</li>
              <li>Colegios y universidades)</li>
              <li>Clubes</li>
              <li>Gimnasios</li>
              <li>Countries y barrios privados</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <b>Servicios particulares</b>
            <ul>
              <li>Ancianos - gerontes - (3ra edad)</li>
              <li>Discapacitados (físicos y mentales)</li>
              <li>
                Incapacitados temporales (lesionados, Intervenidos
                quirúrgicamente,etc.)
              </li>
              <li>Niños</li>
              <li>Tipos de servicio integral</li>
              <li>Internación domiciliaria para adultos</li>
              <li>Internación domiciliaria pediátrica</li>
              <li>Internación domiciliaria gerontológica</li>
              <li>Cuidados paliativos en domicilio particular</li>
              <li>Cuidados paliativos en centro asistencial</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title>Misión</Title>
            <br />
            Garantizar un servicio de excelencia en todas las áreas de la
            medicina y una sólida estructura operativa, maximizando la calidad
            de la prestación y la celeridad informativa.
          </Paragraph>
          <Paragraph>
            Somos una empresa que ha sido creada con el objetivo de brindar un
            servicio de atención domiciliaria. En Bienestar en casa, contamos
            con médicos, enfermeros, y kinesiólogos de calidad en cuidado del
            paciente. Estamos enfocados en el compromiso de todas las personas
            que la componen, para generar un excelente vinculo de empatía,
            respeto, cordialidad y confianza permanente entre el paciente, la
            familia y nuestros profesionales de la salud, satisfaciendo las
            necesidades básicas, de seguridad y contención que favorecen la
            internación en su propio hogar.
          </Paragraph>
          <Paragraph>
            <Title>Visión</Title>
            <br />
            Dar apoyo al universo familiar y empresarial, resguardando la
            preservación de la salud antes que las acciones curativas, brindando
            la tranquilidad, privacidad y atención personalizada que usted y su
            empresa necesita.
          </Paragraph>
          <Paragraph>
            <Title>Profesionalismo</Title>
            <br />
            Contamos con un equipo interdisciplinario altamente capacitado, el
            cual se reúne y establece un consenso para definir una lista de
            prioridades, problemas y necesidades para poder definir las
            estrategias de intervención de cada área.
          </Paragraph>
          <Paragraph>
            <Title>Integridad</Title>
            <br />
            Trabajamos promoviendo el bienestar y la autonomía de los pacientes
            y de sus familiares, con enfermedades graves, progresivas
            proporcionándoles cuidados de manera global y activa, tanto de los
            aspectos físicos, emocionales, psicosociales y espirituales.
          </Paragraph>
          <Paragraph>
            <Title>Objetivos claros</Title>
            <br />
            Apuntamos a controlar los síntomas, dar apoyo emocional y
            comunicacional en el domicilio del paciente, preservando el ambiente
            familiar.
            <br />
            Nuestros cuidados no se proponen prolongar ni acortar la vida, sino
            mejorar su calidad.
          </Paragraph>
          <Paragraph>
            <Title fontSize="18px">Sobre los cuidados domiciliarios</Title>
            <br />
            Muchas veces los pacientes necesitan cuidados exclusivos y/o a
            medida de su tipo de padecimiento. Enfrentar una enfermedad de esta
            clase en un centro de salud público o privado tiene sus limitaciones
            en estos términos por cuestiones lógicas y absolutamente
            comprensibles. Muchas veces existen diferentes requerimientos
            personales difíciles o imposibles de cumplir en estos
            establecimientos que generan angustia y ansiedad en el paciente.
          </Paragraph>
          <Paragraph>
            En Cuidar en Casa brindamos la más amplia gama de servicios
            domiciliarios para el cuidado y apoyo del paciente y su familia
            poniendo énfasis en la calidad de vida, es decir, en la paz, la
            comodidad y la dignidad.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Nosotros;
