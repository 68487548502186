import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Abuela from "./abuela.webp";

const Cuidadoras = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Abuela} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Plantel de Cuidadoras</Title>
          </Paragraph>
          <Paragraph>
            Personas altamente capacitadas (Enfermeras con título no oficial,
            Mucamas especializadas en centros de salud, Mucamas con especialidad
            en geriatría), para solucionar los problemas domésticos realizando
            aquellas tareas que el paciente no puede realizar, además de brindar
            el servicio de cuidadora de enfermos.
          </Paragraph>
          <Paragraph>
            Contamos con una coordinación disponible las 24 horas del día, por
            cualquier inconveniente que pueda surgir y para poder dar respuesta
            a todas sus dudas o inquietudes respecto al servicio.
          </Paragraph>
          <Paragraph>
            Nuestro servicio de cuidadores está ideado para brindar asistencia,
            cuidado y acompañamiento a adultos mayores que no necesiten
            asistencia médica compleja y no presenten cuadros clínicos graves.
            El personal, especialmente elegido para la tarea, de experiencia y
            responsable, puede cubrir los siguientes puntos:
            <ul>
              <li>Higiene y confort (baño en ducha o cama).</li>
              <li>Cambio de ropa de cama e indumentaria.</li>
              <li>
                Control de alimentación. Preparan comidas especiales según la
                dieta que tenga el paciente.
              </li>
              <li>Acompañamiento en rehabilitación.</li>
              <li>Control de signos vitales.</li>
              <li>Acompañamiento a análisis o revisiones médicas.</li>
              <li>
                Mantenimiento, orden y limpieza de dormitorio, cocina y baño.
              </li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Cuidadoras;
