import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import CamaOrtopedicaManual from "./cama-ortopedica-manual.webp";
import CamaOrtopedicaElectrica from "./cama-ortopedica-electrica.webp";
import ColchonAntiescaras from "./colchon-antiescaras.webp";
import ColchonHospitalario from "./colchon-hospitalario.webp";
import Trapecio from "./trapecio.webp";
import Barandas from "./barandas.webp";
import MesaSanatorial from "./mesa-sanatorial.webp";
import PieSuero from "./pie-suero.webp";

const items = [
  { img: CamaOrtopedicaManual, text: "Cama Ortopédica Manual" },
  { img: CamaOrtopedicaElectrica, text: "Cama Ortopédica Electrica" },
  { img: ColchonAntiescaras, text: "Colchón Anti Escaras" },
  { img: ColchonHospitalario, text: "Colchon hospitalario" },
  { img: Trapecio, text: "Trapecio" },
  { img: Barandas, text: "Barandas" },
  { img: MesaSanatorial, text: "Mesa Sanatorial" },
  { img: PieSuero, text: "Pie de suero" },
];
const config = {
    cardWidht: "311px",
    cardHeight: "381px",
};

const CamasOrtopedicas = () => {
  return (
    <>
      <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
        <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
          <Spacer height="40px" />
          <ProductCards items={items} config={config} />
          <Spacer height="40px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

export default CamasOrtopedicas;