import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import FonoaudioImagen from "./fonoaudiologia.webp";

const Fonoaudiologia = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={FonoaudioImagen} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Fonoaudiólogos</Title>
          </Paragraph>
          <Paragraph>
            Los fonoaudiólogos que componen el equipo de "Cuidar en Casa"
            evalúan, diagnostican e intervienen en los trastornos de la
            comunicación de cada paciente, así como también en la comunicación
            humana normal para facilitar el proceso de socialización inherente a
            cada persona.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default Fonoaudiologia;
