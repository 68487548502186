import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";

import Servicios from "./servicios.webp";

const ServiciosProfesionales = () => {
  return (
    <>
      <ImageWide src={Servicios} />
      <MainGrid backgroundColor={({theme}) => theme.colors.grayBackground}>
        <MainContent>
          <TextWide>
            <div style={{ fontWeight: "bold" }}>
              Contamos con personal capacitado
            </div>
            para tratar a tu ser querido no solo con medicina, sino también con
            respeto y amor
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({theme}) => theme.colors.bright}>
          <Spacer height="20px" />
          <Title>¿Quiénes somos?</Title>
          <Paragraph>
            Cuidar en Casa es una empresa que se caracteriza por el
            compromiso y eficiencia en atención y cuidado del paciente, tratando
            de enriquecer el desarrollo y crecimiento de los profesionales Una
            organización que está allí -a veces durante los peores momentos de
            su vida- como un aliado, para garantizar la seguridad y la paz de la
            mente. Somos capaces de hacer esto por el compromiso y la pasión de
            nuestro personal. Es lo que somos. Todo lo que hacemos en Cuidar en
            Casa es guiado por un propósito central claro. Apoyando este
            objetivo son cuatro valores esenciales los que impulsan nuestras
            decisiones de cada día:
          </Paragraph>
          <Paragraph>
            Somos un servicio integral de asistencia y cuidado domiciliario de
            pacientes enfocado no solo en la salud, sino también en la
            contención y en el asesoramiento de familiares y personas cercanas.
            Cuidar en Casa pone a disposición profesionales especializados y
            matriculados en medicina, enfermería, radiología, kinesiología,
            nutrición, entre otros. Tranquilidad y seguridad: módulos de
            enfermería y cuidadores a domicilio de 4, 6, 8, 10, 16, 12 y 24 horas.
            Además: traslados de pacientes, atención médica domiciliaria,
            alquiler de ortopedia, curaciones. También en hospitales y
            geriátricos.
          </Paragraph>
          <Paragraph>
            Cuidar en Casa privilegia la preservación de la salud ante que las
            acciones curativas, brinda la tranquilidad, privacidad y atención
            personalizada que Ud., su familia y su empresa necesita. <br />
            INTEGRIDAD,siempre hacer lo correcto.
            <br />
            RESPETO, todo el mundo lo merece.
            <br />
            COMPROMISO, nos preocupamos profundamente por no decepcionar a
            nadie.
            <br />
            CUIDADO, ponemos nuestro corazón en el trabajo.
            <br />
          </Paragraph>
          <Paragraph>
            Solicite una entrevista para evaluar juntos la ventaja de contar con
            un equipo altamente profesional, sustentado en la eficiencia y la
            ética, a una relación costo beneficio sumamente competitiva, incluso
            sobre la posibilidad de contar con personal propio. En tal sentido
            ya no tendrá que preocuparse por ausencias, vacaciones capacitación,
            actualización, riesgos laborales; pudiendo flexibilizar la dotación
            convenientemente acorde a su real necesidad de demanda en cada
            momento.
          </Paragraph>
          <Paragraph>
            <Title>Servicios</Title>
          </Paragraph>
          <Paragraph>
            Brindamos soluciones a aquellas personas que no disponen de tiempo,
            movilidad y dedicación para realizarlo, usted sólo elíjanos y su
            familia y/o empresa se sentirá en manos confiables y expertas que
            cuidaran de sus seres queridos y empleados.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Algunas de nuestras especialidades:</Title>
          </Paragraph>
          <Paragraph>
            La metodología aplicada en los servicios se encuentra
            particularmente estudiada, documentada, estandarizada y por tanto
            optimizada (organizamos regularmente jornadas de perfeccionamiento
            con el objetivo de avanzar hacia la calidad total) en toda la zona
            de cobertura, de manera que ante -por ejemplo- el eventual cambio de
            un profesional (sea por rotación horaria, licencias...) <br />
            Ud. no perciba ninguna diferencia.
          </Paragraph>
          <Paragraph>
            Médicos, kinesiología, terapista ocupacional, fonoaudiólogos,
            psicólogos, fisiatras, nutricionistas, licenciados en enfermería,
            enfermería, auxiliares, cuidadoras, Asistentes Terapéutico,
            Podología, pedicura, farmacia, aplicación de inyecciones.
          </Paragraph>
          <Paragraph>
            Alquiler de aparatos e insumos: camas ortopédicas, sillas de ruedas,
            andadores, pañales. <br />
            Otros servicios: Masajes, Peluquería, Belleza de manos y pies.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Servicio de transporte</Title> <br /> Nos caracterizamos por
            el compromiso, excelencia y eficiencia en atención, cuidado y
            asesoramiento a nuestros pacientes, coordinando toda la prestación
            desde el inicio, hasta el final de la misma, con una impecable
            logística y seguimiento, que nos permite dar respuesta a todos los
            requerimientos efectuados por usted durante el tratamiento.
          </Paragraph>
          <Paragraph>
            Brindamos soluciones a aquellas personas que no disponen de tiempo,
            movilidad y dedicación para realizarlo, usted sólo elíjanos y su
            familia y/o empresa se sentirá en manos confiables y expertas que
            cuidaran de sus seres queridos y empleados.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

const TextWide = styled.div`
  color: ${({theme}) => theme.colors.bright};
  padding: 20px 0;
  font-size: 35px;
`;

export default ServiciosProfesionales;
