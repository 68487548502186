import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Paragraph,
  Spacer,
  ImageWide,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import Rodilla from "./rodilla.webp";
import Traumatologica from "./traumatologica.webp";
import Deportiva from "./deportiva.webp";
import Reumatologica from "./reumatologica.webp";
import Respiratoria from "./respiratoria.webp";
import Neurologica from "./neurologica.webp";

const items = [
  { img: Traumatologica, text: "Traumatológica" },
  { img: Deportiva, text: "Deportiva" },
  { img: Reumatologica, text: "Reumatológica" },
  { img: Respiratoria, text: "Respiratoria" },
  { img: Neurologica, text: "Neurológica" },
];
const config = {};

const KinesiologiaDomicilio = () => {
  return (
    <>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Rodilla} />
        </MainContent>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Paragraph>
            Los kinesiólogos que componen nuestro equipo se especializan en la
            mantención de la capacidad fisiológica del individuo y en la
            prevención de sus alteraciones. Su formación le permite intervenir
            en la recuperación y rehabilitación psicomotora, mediante la
            aplicación de técnicas y procedimientos de naturaleza física y bajo
            indicación médica.
          </Paragraph>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.red}>
        <MainContent noPadding="true">
          <Spacer height="40px" />
          <BoldTitle>Servicio de Rehabilitación</BoldTitle>
          <Spacer height="40px" />
          <ProductCards items={items} config={config} />
          <Spacer height="40px" />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor={({ theme }) => theme.colors.defaultBackground}>
        <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
          <Spacer height="20px" />
        </MainContent>
      </MainGrid>
    </>
  );
};

const BoldTitle = styled.div`
  font-size: 45px;
  font-family: "Arial Bold", sans-serif;
  font-weight: bold;
  color: white;
  opacity: 0.4;
  letter-spacing: 0.1em;
  text-align: center;
`;

export default KinesiologiaDomicilio;
