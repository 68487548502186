import React, { Component } from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  TextWide,
} from "../../services/common";

class Contactenos extends Component {
  constructor(props) {
    super();
    this.state = { nombre: "", email: "", asunto: "", mensaje: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    this.setState({ [fieldName]: fieldValue });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.clearForm();
  }

  clearForm() {
    for (var fieldName in this.state) {
      this.setState({ [fieldName]: "" });
    }
  }

  render() {
    return (
      <>
        <MainGrid backgroundColor={({ theme }) => theme.colors.dark}>
          <MainContent>
            <TextWide>
              “Un día mi familia pasó por una situación difícil. Con el trabajo
              de cada uno y nuestro padre enfermo los tiempos se complicaban
              cada vez más. Es por eso que decidimos recurrir a Cuidar en Casa
              Quedamos muy conformes con los servicios contratados ya que, la
              persona que cuidaba a mi padre sabía perfectamente su trabajo,
              ayudándolo a recuperarse mediante métodos profesionales. Estamos
              muy contentos con la atención brindada por Cuidar en Casa”.
              <p style={{ fontWeight: "bold", textAlign: "right" }}>
                Julio Herrera López de Palermo
              </p>
            </TextWide>
          </MainContent>
        </MainGrid>
        <MainGrid
          backgroundColor={({ theme }) => theme.colors.defaultBackground}
        >
          <MainContent backgroundColor={({ theme }) => theme.colors.bright}>
            <Paragraph>
              <Title fontSize="30px">Envíenos su Consulta</Title>
              <br />
              Le responderemos a la brevedad
            </Paragraph>
            <TwoColumns>
              <Form onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  value={this.state.nombre}
                  name="nombre"
                  onChange={this.handleChange}
                  placeholder="Nombre *"
                />
                <input
                  type="text"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Email *"
                />
                <input
                  type="text"
                  value={this.state.asunto}
                  name="asunto"
                  onChange={this.handleChange}
                  placeholder="Asunto *"
                />
                <textarea
                  type="text"
                  value={this.state.mensaje}
                  name="mensaje"
                  onChange={this.handleChange}
                  placeholder="Mensaje *"
                  rows="6"
                />
                <input type="submit" value="ENVIAR" />
              </Form>
              <div>
                <Paragraph>
                  Solicite una entrevista para evaluar juntos la ventaja de
                  contar con un equipo altamente profesional, sustentado en la
                  eficiencia y la ética, a una relación costo beneficio
                  sumamente competitiva, incluso sobre la posibilidad de contar
                  con personal propio.
                </Paragraph>
                <Paragraph>
                  En tal sentido ya no tendrá que preocuparse por ausencias,
                  vacaciones capacitación, actualización, riesgos laborales;
                  pudiendo flexibilizar la dotación convenientemente acorde a su
                  real necesidad de demanda en cada momento.
                </Paragraph>
              </div>
            </TwoColumns>
          </MainContent>
        </MainGrid>
      </>
    );
  }
}

const Form = styled.form`
  display: flex;
  flex-direction: column;

  &:nth-child(n) {
    margin: 5px;
  }

  input[type="text"] {
    padding: 10px;
    margin: 5px 0;
    border-radius: 3px;
    border: 1px solid gray;
    font-family: ${({ theme }) => theme.fonts.paragraphs};
  }

  textarea {
    padding: 10px;
    margin: 5px 0;
    border-radius: 3px;
    border: 1px solid gray;
    font-family: ${({ theme }) => theme.fonts.paragraphs};
  }

  input[type="submit"] {
    padding: 10px 30px;
    margin: 5px auto;
    border-radius: 3px;
    border: 1px solid gray;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.bright};
    font-family: ${({ theme }) => theme.fonts.paragraphs};
    transition: all 0.5s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGreen};
      box-shadow: 5px 5px 9px -5px rgba(0, 0, 0, 0.75);
    }
  }
`;

const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries["desktop"]} {
    flex-direction: row;
    form {
      flex-basis: 50%;
    }
    div {
      flex-basis: 50%;
      padding-left: 15px;
      align-items: flex-start;
    }
  }
`;

export default Contactenos;
