import React from "react";
import styled from "styled-components";

import Assistant from "./asistant.png";

const FullContactCard = () => {
  return (
    <TarjetaContacto>
      <Encabezado bgImage={Assistant}>
        <TelefonosContactenos>
          <Titulos>Contactenos</Titulos>
          <Telefonos>{window.siteConfig.CONTACT_DATA.PHONE_PRIMARY}</Telefonos>
          <Telefonos>
            {window.siteConfig.CONTACT_DATA.PHONE_SECONDARY}
          </Telefonos>
        </TelefonosContactenos>
        <WhatsappContactenos>
          <Titulos>WhatsApp</Titulos>
          <Telefonos>
            {window.siteConfig.CONTACT_DATA.WHATSAPP_PRIMARY}
          </Telefonos>
          <Telefonos>
            {window.siteConfig.CONTACT_DATA.WHATSAPP_SECONDARY}
          </Telefonos>
        </WhatsappContactenos>
        <Urgencias>
          <Titulos2>Urgencias las 24 horas</Titulos2>
        </Urgencias>
        <Urgencias>
          <Telefonos2>
            {window.siteConfig.CONTACT_DATA.PHONE_24HS_PRIMARY}
          </Telefonos2>
          <Telefonos2>
            {window.siteConfig.CONTACT_DATA.PHONE_24HS_SECONDARY}
          </Telefonos2>
        </Urgencias>
      </Encabezado>
      <TelefonoPrincipal>
        <h1>{window.siteConfig.CONTACT_DATA.PHONE_PRIMARY}</h1>
      </TelefonoPrincipal>
    </TarjetaContacto>
  );
};

const TarjetaContacto = styled.div`
  display: flex;
  flex-direction: column;
`;

const Encabezado = styled.div`
  width: 470px;

  display: grid;
  grid-template-columns: 30% 30% 40%;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;

  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-position: right;
  background-size: 65% 100%;
  z-index: 0;
`;

const TelefonosContactenos = styled.div`
  background-color: ${({theme}) => theme.colors.menuBackground} ; // original #9adde1
  font-size: 16px;
  padding-bottom: 5px;
`;
const WhatsappContactenos = styled.div`
  background-color: ${({theme}) => theme.colors.menuBackground}; // original #9adde1
  font-size: 16px;
`;

const Urgencias = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; // necesario para el fondo blur
  font-size: 16px;
  font-weight: lighter;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${({theme}) =>
      theme.colors.menuBackground}; // original: #9adde1
    opacity: 0.7;
    z-index: -1;
  }
`;

const TelefonoPrincipal = styled.div`
  display: flex;
  background-color: ${({theme}) => theme.colors.menuBackground}; // original #14767d
  justify-content: center;
  color: ${({theme}) => theme.colors.primary};
  font-family: "Forum", cursive;
  font-size: 13px;
  letter-spacing: 0.2em;

  h1 {
    padding: 0px;
    margin: 7px;
  }
`;

const Titulos = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.colors.primary};
  `;

const Titulos2 = styled.div`
  font-weight: bold;
  text-align: center;
  margin: auto;
  color: ${({theme}) => theme.colors.primary};
`;

const Telefonos = styled.div`
  text-align: center;
`;
const Telefonos2 = styled.div`
  text-align: center;
`;

export default FullContactCard;
