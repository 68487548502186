import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import MuletasAluminio from './muletas-aluminio.webp';
import BastonCanadiense from './baston-canadiense.webp';
import BastonTAluminio from './baston-t-aluminio.webp';
import BastonTMadera from './baston-t-madera.webp';
import BastonTripode from './baston-tripode.webp';
import BastonCuadruple from './baston-tripode.webp';

const items = [
    { img: MuletasAluminio, text: "Muletas de Aluminio" },
    { img: BastonCanadiense, text: "Bastón Canadiense" },
    { img: BastonTAluminio, text: "Bastón 'T' de Aluminio" },
    { img: BastonTMadera, text: "Bastón 'T' de Madera" },
    { img: BastonTripode, text: "Bastón Trípode" },
    { img: BastonCuadruple, text: "Bastón Cuádruple" },
  ];
  const config = {
      cardWidht: "311px",
      cardHeight: "381px",
  };
  
  const MuletasBastones = () => {
    return (
      <>
        <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
          <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
            <Spacer height="40px" />
            <ProductCards items={items} config={config} />
            <Spacer height="40px" />
          </MainContent>
        </MainGrid>
      </>
    );
  };
  
  export default MuletasBastones;