import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Personal from "./personal-domestico.webp";

const PersonalDomestico = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Personal} />
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor="#a9a5a8">
        <MainContent>
          <TextWide>
            Porque la higiene, también es salud, no solo del paciente, sino de
            quienes conviven con él.
          </TextWide>
        </MainContent>
      </MainGrid>
      <MainGrid backgroundColor="#dae39b">
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Personal doméstico</Title>
          </Paragraph>
          <Paragraph>
            La presentación higiénica de su casa siempre es primero y luego su
            decoración. La limpieza de una casa es muy importante para que los
            invitados vuelvan a ir a visitarle. Según las normas de una casa y
            el sentido común, uno debe tener una casa no solamente linda, pero
            también, limpia, porque si hay mucha falta de higiene por toda la
            casa, la gente no va a querer ir más a visitarle. Por eso, si usted
            necesita ayuda con este tema por falta de tiempo de limpiar por el
            trabajo u otro compromiso, este artículo sobre personal doméstico
            zona sur le vendría muy bien.
          </Paragraph>
          <Paragraph>
            Antes de encontrar al grupo de personal doméstico para todas sus
            necesidades, hay muchas consideraciones importantes que usted
            tendría que pensar antes de solicitar al equipo ideal. Aunque no
            parezca, hay que pensar en los siguientes factores para poder
            encontrar el equipo ideal de personal doméstico: la comodidad de su
            equipo nuevo de personal doméstico los otros empleados y las
            necesidades de usted y su familia, el lenguaje corporal del
            personal, las expectativas y rutinas diarias de limpieza de su casa,
            la cantidad exacta de dinero para todas las tareas diferentes en su
            casa y una entrevista con más de un equipo de personal doméstico
            para encontrar lo mejor. Luego de cumplir con estos tips, usted verá
            que es mucho más fácil de elegir un buen equipo de personal
            doméstico en cualquier parte del mundo.
          </Paragraph>
          <Paragraph>
            Para comenzar, luego de tener una entrevista con más de un equipo
            sería beneficioso para ambas partes así se conocen un poco más y se
            entienden mejor. Con una entrevista con varias personas, usted podrá
            ver y analizar todos los atributos positivos y negativos de cada
            persona y cuáles le convienen. También, hay que pedir algunas
            referencias de otros empleadores previos para poder tomar la
            decisión correcta y determinar si la persona es de confianza o no.
            Además, con una entrevista, si una persona es mentirosa, nerviosa o
            desmotivada basta con la observación de su lenguaje corporal para
            que vayamos formando una opinión al respecto. Por último, si durante
            la entrevista, uno de ustedes dos no se sienten de acuerdo o
            cómodos, habría que buscar a otra persona para evitar futuros
            inconvenientes.
          </Paragraph>
          <Paragraph>
            Además de la personalidad de su equipo de personal doméstico, hay
            que clarificar las expectativas y la rutina diaria de limpieza de la
            casa para que los empleados nuevos no tengan que preguntarle siempre
            y pueden trabajar siempre si usted está en casa o no. También, hay
            que decir bien las tareas específicas en el principio y cómo se
            limpian los cuartos y todos sus muebles. De esta manera, el personal
            doméstico sabrá todo antes. Además de las expectativas, siempre hay
            que confirmar los precios y cuánto cobrará por mes o por semana así
            no hay ninguna duda. A veces hay que tener precios separados
            dependiendo de la tarea a mano porque algunas llevan más tiempo y
            más trabajo que otras. Por eso, cuando llegue la hora de elegir un
            equipo de personal doméstico, ¡siendo honesto y justo siempre será
            el mejor camino!
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Equipo básico de limpieza</Title>
          </Paragraph>
          <Paragraph>
            Contar con el equipo adecuado y los productos necesarios para
            realizar la limpieza del hogar es fundamental para ganar tiempo y
            eficiencia. Cortes accidentales, quemaduras, ingestión de sustancias
            Los imprescindibles para realizar la limpieza.
          </Paragraph>
          <Paragraph>
            <b>1) Recogedor, escoba </b>
            <br />
            Infaltables para recoger el polvo a la usanza tradicional, aun
            teniendo aspirador siempre son necesarios. Colgar o poner boca
            arriba el recogedor para que no se deformen, lavar la escoba con
            agua jabonosa una vez al mes.
          </Paragraph>
          <Paragraph>
            <b>2) Aspiradora</b>
            <br />
            El electrodoméstico más práctico a la hora de limpiar la casa, no
            solo quita el polvo, restos de comidas de los tapizados, mesada,
            etc. sino que limpia moquetas y alfombras.
          </Paragraph>
          <Paragraph>
            <b>3) Fregonas y cubos</b>
            <br />
            Lo ideal es tener un cubo con agua jabonosa y otro con agua limpia
            para enjuagar el mocho. Evitará que el agua jabonosa se ensucie a
            medida que va fregando.
          </Paragraph>
          <Paragraph>
            <b>4) Paños, trapos y bayetas</b>
            <br />
            Aprovechar las toallas, sabanas o camisetas viejas para secar.
            Siempre hay preferencias para utilizar unos por otros, para los
            cristales los que no desprenden pelusa, las toallas para la vajilla
            por que absorben mejor el agua y los demás para atrapar el polvo. No
            mezclar las bayetas y estropajos del baño con las de la cocina.
          </Paragraph>
          <Paragraph>
            <b>5) Cepillos</b>
            <br />
            Necesitará un cepillo para ropa, uno pequeño para las juntas de los
            alicatados de baños y cocinas y otro para quitar manchas rebeldes de
            los suelos.
          </Paragraph>
          <Paragraph>
            <b>6) Elección de los productos de limpieza</b>
            <br />
            Elegir los productos de limpieza es algo complicado, dependerán de
            las preferencias de la persona que limpia, pero su decisión no será
            fácil ya que la oferta del mercado es muy versátil.
          </Paragraph>
          <Paragraph>
            Los productos básicos son: lavavajillas (uno para lavar a mano y
            otro para el lavavajillas), desinfectante como el amoniaco o lejía
            para fregar suelos y alicatados de la cocina y los baños; los
            productos complementarios como: bórax, alcohol de quemar,
            trementina, limpiacristales, desengrasante, etc.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

const TextWide = styled.div`
  color: white;
  padding: 20px 0;
  font-size: 25px;
`;

export default PersonalDomestico;
