import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Asistentes from "./asistentes.webp";

const AsistentesGerontologicos = () => {
  return (
    <>
      <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
        <MainContent noPadding="true">
          <ImageWide src={Asistentes} />
        </MainContent>
        <MainContent backgroundColor={({theme}) => theme.colors.bright}>
          <Paragraph>
            <Title>Asistentes gerontológicos</Title>
          </Paragraph>
          <Paragraph>
          La práctica profesional de los Asistentes Gerontológicos de Cuidar en Casa consiste en la ayuda de tareas concernientes al aseo personal, preparación de alimentos nutritivos y balanceados, confección de dietas especializadas según la patología de cada paciente y acompañamiento en la gestión de trámites y actividades recreativas para el paciente.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default AsistentesGerontologicos;
