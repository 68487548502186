import React from 'react'
import styled from 'styled-components';

import { MainGrid, MainContent } from '../../services/common';

import Phone from './phoneIcon.webp';
import Envelope from './envelopeIcon.webp';
import Info from './infoIcon.webp';
import Whatsapp from "./whatsapp_Rounded.png";

const Footer = () => {
    return (
        <>
        <MainGrid backgroundColor="#dae39b">
            <MainContent>
                <DataRibbon>
                    <div><img src={Phone} alt=""/> Llámenos {window.siteConfig.CONTACT_DATA.PHONE_PRIMARY}</div>
                    <div><img src={Envelope} alt=""/><a href={`mailto:${window.siteConfig.CONTACT_DATA.EMAIL}`}>{window.siteConfig.CONTACT_DATA.EMAIL}</a></div>
                    <div><img src={Info} alt=""/><a href="/contactenos.html">Más información &gt;&gt;</a></div>
                </DataRibbon>
            </MainContent>
        </MainGrid>
        <Background>
            ©{new Date().getFullYear()} Cuidar en Casa Salud by <a href="https://veron.com.ar" rel="noopener noreferrer">Veron Digital Design</a>
        </Background>
        <FloatingWhatsapp
        href={`https://api.whatsapp.com/send?phone=${window.siteConfig.CONTACT_DATA.WHATSAPP_ONLINE_CHAT}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Whatsapp} alt="Cheatea con nosotros" />
      </FloatingWhatsapp>
        </>
    )
}

const DataRibbon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;


    div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;

        img {
            padding: 10px;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }

@media ${({theme}) => theme.mediaQueries["desktop"]}{
        flex-direction: row;
    }
`;

const Background = styled.div`
    background: ${({theme}) => theme.colors.bright};
    margin: 15px auto;

    font-size: 12px;
    text-align: center;
    color: ${({theme}) => theme.colors.primary};

    a {
        text-decoration: none;
        color: ${({theme}) => theme.colors.primary};
    }
`;

const FloatingWhatsapp = styled.a`
  position: fixed;
  bottom: 40px;
  right: 40px;

  img {
    border-radius: 50%;
    box-shadow: 2px 2px 3px #999;
  }
`;

export default Footer;
