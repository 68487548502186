export default [
    { name: "Planes", type: "link", link: "quienes-somos.html" },
    { name: "Servicios Profesionales", type: "modal", link: "#servicios-profesionales", modalLinks: [
      {name: "Servicios Profesionales", link: "servicios-profesionales.html"},
      {name: "Médico Domiciliario", link: "medico-domiciliario.html"},
      {name: "Enfermería Profesional", link: "enfermeria-profesional.html"},
      {name: "Inyectables y Curaciones", link: "inyectables-curaciones.html"},
      {name: "Cuidadoras", link: "cuidadoras.html"},
      {name: "Kinesiología a Domicilio", link: "kinesiologia-a-domicilio.html"},
      {name: "Radiología a Domicilio", link: "radiologia-a-domicilio.html"},
      {name: "Fonoaudiología", link: "fonoaudiologia.html"},
      {name: "Acompañante Terapeutico", link: "acompanante-terapeutico.html"},
      {name: "Asistentes Gerontológicos", link: "asistentes-gerontologicos.html"},
    ] },
    { name: "Cuidados Paliativos", type: "modal", link: "#cuidados-paliativos", modalLinks: [
      {name: "Cuidados Paliativos", link: "cuidados-paliativos.html"},
      {name: "Traslados", link: "traslados.html"},
    ]},
    { name: "Equipos / Alquiler y Venta", type: "modal", link: "#equipos-alquier-venta", modalLinks: [
      {name: "Equipos / Alquiler y Venta", link: "rentals.html"},
      {name: "Camas ortopédicas", link: "camas-ortopedicas.html"},
      {name: "Sillas de ruedas", link: "sillas-ruedas.html"},
      {name: "Andadores", link: "andadores.html"},
      {name: "Muletas y Bastones", link: "muletas-bastones.html"},
      {name: "Nebulizadores", link: "nebulizadores.html"},
      {name: "Equipos de Oxígeno", link: "equipos-oxigeno.html"},
    ]},
    { name: "Personal Doméstico", type: "link", link: "personal-domestico.html"},
    { name: "Contáctenos", type: "link", link: "contactenos.html"},
  ];