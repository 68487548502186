import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Acompaniante from "./acompañante.webp";

const AcompanianteTerapeutico = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Acompaniante} backgroundPosition="top"/>
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Acompañante terapéutico</Title>
          </Paragraph>
          <Paragraph>
            El acompañante terapéutico permite la continuidad de tratamientos
            ambulatorios o internaciones domiciliarias, mediante la contención y
            asistencia de cada uno de los pacientes. El acompañamiento
            terapéutico es una herramienta eficaz en la reducción significativa
            de recaídas y hospitalizaciones, así como también en la reinserción
            de la persona al lazo social.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">¿Qué es un acompañante terapéutico?</Title>
          </Paragraph>
          <Paragraph>
            El acompañamiento terapéutico es una práctica profesional, en el
            ámbito de la Salud Mental, que se realiza en la Argentina desde los
            años 70 y demostró ser una herramienta eficaz en el tratamiento de
            pacientes crónicos y agudos.
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                El AT es un agente auxiliar de salud con conocimientos básicos
                de psiquiatría y psicología.
              </li>
              <li>
                El AT está preparado para acompañar al sujeto en las diferentes
                fases del tratamiento, en sus crisis y en sus dolencias desde un
                plano vivencial, no interpretativo, en un estar ahí
                constituyendo una presencia receptiva, contenedora y confiable.
              </li>
              <li>
                El AT promueve en el paciente mayor autonomía, lo ayuda a
                sostener o reestablecer los vínculos familiares, sociales y
                laborales que se ven afectados.
              </li>
              <li>
                El AT previene y acompaña los conflictos propios de la vida
                cotidiana, genera una red de contención familiar que ayuda a la
                recuperación del paciente e intenta evitar la cronificación y la
                estigmatización social.
              </li>
              <li>
                El AT trabaja en forma interdisciplinaria teniendo en cuenta la
                singularidad de cada sujeto y siguiendo los lineamientos
                planteados por el profesional que lleva adelante la dirección
                del tratamiento.
              </li>
              <li>
                El AT es un recurso que fortalece y complementa los abordajes
                institucionales en la vida cotidiana del sujeto; favoreciendo la
                continuidad entre las distintas etapas del tratamiento,
                intentando evitar recaídas en los momentos de especial
                fragilidad psíquica.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">
              ¿En qué casos se puede solicitar un acompañante terapéutico?
            </Title>
          </Paragraph>
          <Paragraph>
            ¿En qué casos se puede solicitar un acompañante terapéutico?
            <ul>
              <li>Internación Domiciliaria.</li>
              <li>Internación Institucional.</li>
              <li>Proceso de Externación.</li>
              <li>Hospital de Día.</li>
              <li>Pacientes sin contención familiar.</li>
              <li>Integración Escolar.</li>
              <li>¿Quiénes necesitan un acompañante terapéutico?</li>
              <li>
                Trastornos psicóticos: esquizofrenia, melancolía, paranoia y
                cuadros bipolares.
              </li>
              <li>Trastornos del ánimo: depresión, bipolaridad.</li>
              <li>Trastornos de la conducta alimentaria: anorexia, bulimia.</li>
              <li>Trastornos de la personalidad.</li>
              <li>Alteraciones severas de la conducta en la infancia.</li>
              <li>
                Enfermedades psíquicas y incapacitantes en la infancia-
                adolescencia.
              </li>
              <li>
                Patologías psiquiátricas y neurológicas del adulto y adultos
                mayores. Demencias.
              </li>
              <li>
                Neurosis obsesiva, histerias y neurosis de angustia (fobias).
              </li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default AcompanianteTerapeutico;
