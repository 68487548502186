import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import EnfermeriaPro from "./enfermeria-pro.webp";

const EnfermeriaProfesional = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={EnfermeriaPro} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Enfermería profesional domiciliaria</Title>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Plantel de Enfermería</Title>
            <br />
            Licenciadas/os en Enfermería, Enfermeras/os profesionales
            universitarias y auxi­liares de enfermería, con amplia experiencia
            en terapia intensiva, clínica general, quemados, enfermedades
            infecciosas, oncología, traumatología y neurología.
          </Paragraph>
          <Paragraph>
            El servicio de enfermería está recomendando en casos de pacientes
            que necesiten asistencia compleja y cuidados especiales,
            relacionados cuadros clínicos graves.
          </Paragraph>
          <Paragraph>
            Nuestro servicio de enfermería es realizado por profesionales
            matriculados, de experiencia, Brindamos Guardias y/o visitas de
            enfermería para realizar las tareas de:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>Higiene y confort (baño en ducha o cama)</li>
              <li>Cambio de ropa de cama e indumentaria</li>
              <li>Control de alimentación y preparación de alimentos</li>
              <li>Acompañamiento en rehabilitación</li>
              <li>Control de signos vitales</li>
              <li>Acompañamiento a análisis o revisiones médicas</li>
              <li>
                Mantenimiento, orden y limpieza de dormitorio, cocina y baño
              </li>
              <li>Aplicaciones endovenosas, subcutáneas e intramusculares</li>
              <li>Curaciones</li>
              <li>Controles</li>
              <li>Higiene y confort del paciente</li>
              <li>Manejo de sondas</li>
              <li>Control de alimentación enteral y parenteral, etc</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">
              ¿En qué casos necesita contratar una enfermera?
            </Title>
            <br />
            Algunos de los casos en los que debe contratar una enfermera:
            <ul>
              <li>Si el paciente necesita ser alimentado por sonda</li>
              <li>Si presenta heridas que necesiten tratamiento</li>
              <li>Si necesita que se le administre oxígeno</li>
              <li>Si padece una enfermedad terminal (cuidados paliativos)</li>
              <li>Si el paciente tiene realizada una colostomía</li>
              <li>Asistencia Permanente</li>
            </ul>
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default EnfermeriaProfesional;
