import React, { Component } from "react";
import styled from "styled-components";

import Card from "./Card";

class ProductCards extends Component {
  render() {
    const { config } = this.props;
    const { items } = this.props;

    return (
      <Container>
        {items.map((item) => {
          return (
            <Card
              key={Math.random()}
              img={item.img}
              backgroundColor={config.cardColor}
              textColor={config.cardTextColor}
              width={config.cardWidht}
              height={config.cardHeight}
            >
              {item.text}
            </Card>
          );
        })}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default ProductCards;
