import React from "react";
import ReactMenuModal from "./react-menu-modal/ReactMenuModal";

import MenuItems from "./menuStructure";

const Menu = () => {
  return <ReactMenuModal menuItems={MenuItems}/>;
};

export default Menu;
