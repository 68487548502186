import React, { Component } from "react";
import styled from "styled-components";

class Card extends Component {
  removePx(sizeString){
    return parseInt(sizeString.slice(0, -2));
  }

  getImageHeight(height, textHeight) {
    var heightNum = this.removePx(height) - this.removePx(textHeight);
    return `${heightNum}px`;
  }

  render() {
    const {
      backgroundColor,
      textColor,
      children,
      img,
      height,
      width,
    } = this.props;

    var innerWidth = width || "170px";
    var innerHeight = height || "190px";
    var textHeight = "30px";
    var imgHeight = this.getImageHeight(innerHeight, textHeight);


    return (
      <Container width={innerWidth} height={innerHeight}>
        <Image img={img} height={imgHeight} />
        <Text backgroundColor={backgroundColor} textColor={textColor}>
          {children}
        </Text>
      </Container>
    );
  }
}

const Container = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: 10px;
  background-color: white;
`;

const Image = styled.div`
  height: ${({ height }) => height};
  background-image: url("${({ img }) => img}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;

  ${Container}:hover &{
    opacity: 0.7;
    filter: grayscale(1);
    transform: scale(0.95);
  }
`;

const Text = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || "black"};
  color: ${({ textColor }) => textColor || "white"};
  padding: 10px 15px;
  height: ${({textHeight}) => textHeight};
  text-align: center;
`;

export default Card;
