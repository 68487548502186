import React from "react";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Radiologia from "./radiologia.webp";

const RadiologiaDomicilio = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Radiologia} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Radiología a domicilio</Title>
          </Paragraph>
          <Paragraph>
            Servicio de radiología portátil destinado a controles laborales para
            empresas, controles anuales de personal, atención especializada para
            geriátricos y domicilios.
          </Paragraph>
          <Paragraph>
            <b>Modalidades:</b>
            <ul>
              <li>
                <b>Programado:</b> para pacientes con placas programadas para
                controles médicos. Se combina fecha y hora de realización.
              </li>
              <li>
                <b>Urgencias:</b> para pacientes que requieren la prestación
                dentro del día solicitado por el médico.
              </li>
              <li>
                <b>Emergencias:</b> para estos pacientes, el sistema de
                emergencia de radiología domiciliaria, dispone de una espera
                máxima de 90 minutos, luego de recibido el pedido.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            El servicio consiste en una unidad móvil con equipamiento portátil
            de radiología que realiza todo tipo de radiografías convencionales
            requeridas por el profesional médico.
          </Paragraph>
          <Paragraph>
            Las placas tomadas son entregadas al momento de la realización y, si
            se solicita informe médico, la entrega tiene una demora de entre 24
            a 48 horas.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

export default RadiologiaDomicilio;
