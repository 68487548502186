export default {
    colors: {
        primary: '#535a0a',
        menuBackground: '#e8dfc5',
        defaultBackground: '#dae39b',
        grayBackground: '#a9a5a8',
        bright: 'white',
        dark: '#252a04',
        red: '#ff6161',
        green: '#7bbe63',
        lightGreen: "#66cf5b",
    },
    fonts: {
        titlesBig: '"Helvetica", sans-serif',
        paragraphs: '"Helvetica", sans-serif',
    },
    mediaQueries: {
        'desktop': `only screen and (min-width: 900px)`
    }
};