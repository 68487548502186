import React from "react";
import styled from "styled-components";

import {
  MainGrid,
  MainContent,
  Title,
  Paragraph,
  ImageWide,
} from "../../services/common";

import Medico from "./medico.webp";

const MedicoDomiciliario = () => {
  return (
    <>
      <MainGrid backgroundColor="#dae39b">
        <MainContent noPadding="true">
          <ImageWide src={Medico} />
        </MainContent>
        <MainContent backgroundColor="white">
          <Paragraph>
            <Title>Servicio médico domiciliario</Title>
          </Paragraph>
          <Paragraph>
            Hacemos un breve resumen de la historia de la visita médica al hogar
            y exponemos los motivos de la visita al hogar: enfermedades agudas,
            procesos crónicos, discapacidades, evaluación del entorno familiar e
            higiénico-epidemiológico del paciente, ingresos domiciliarios, altas
            hospitalarias precoces y pacientes con enfermedades terminales o con
            afecciones dispensa rizadas, entre otros.
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">
              Ventajas de las visitas médicas domiciliarias:
            </Title>
          </Paragraph>
          <Paragraph>
            <SpacedUL>
              <li>
                La visita al hogar permite al médico obtener información sobre
                la unidad familiar y su funcionamiento, la interacción entre sus
                miembros y el rol del paciente. Esto no puede lograrse en el
                ambiente “artificial” del consultorio. No hay un sustituto
                satisfactorio para la visita domiciliaria.
              </li>
              <li>
                En el consultorio el médico solo tiene acceso al paciente; en la
                visita al hogar puede atenderse a toda la unidad familiar.
              </li>
              <li>
                La atención en el hogar permite un egreso más rápido del
                hospital; es una alternativa aceptable para la atención
                institucional a largo plazo y para aquellos que sufren alguna
                discapacidad que comprometa la movilidad o la independencia.
                Muchos de éstos pacientes son ancianos. En adición, esta forma
                de atención es económicamente más eficiente que el ingreso en el
                hospital.
              </li>
              <li>
                Los pacientes se enfrentan mejor a su enfermedad si permanecen
                en un ambiente familiar.
              </li>
              <li>
                Para los ancianos la visita y atención en el hogar son muy
                satisfactorias, sobre todo en el caso de tener discapacidades
                físicas. El ingreso en el hospital de un anciano puede tener
                consecuencias indeseables motivadas por la separación de su
                medio.
              </li>
              <li>
                En el hogar se pueden detectar condiciones que rodean al enfermo
                y que pueden ser nocivas para su salud: estado estructural de la
                vivienda, condiciones inadecuadas de vida, riesgos de
                accidentes, hacinamiento, higiene deficiente, mal funcionamiento
                familiar, insuficiente apoyo y ayuda al paciente por parte de la
                familia, poca adherencia al tratamiento, entre otros.
              </li>
              <li>
                La visita del médico al hogar inspira al paciente confianza y
                seguridad. El médico, por su parte, logra mejor empatía que la
                que se puede establecer en el consultorio y los pacientes
                sienten que el médico los acompaña en su padecimiento, que son
                atendidos como si cada uno de ellos fuese un familiar o amigo.
              </li>
              <li>
                Aunque la ciencia de la medicina probablemente pueda ejercerse
                de mejor forma en el bien equipado ambiente del consultorio, el
                arte de la medicina se aplica mejor en el hogar del paciente, y
                por ello es conveniente combinar ambas actuaciones.
              </li>
              <li>
                Los casos que requieren visitas domiciliarias son múltiples.
                Algunos de los más frecuentes:
              </li>
              <li>
                Evaluación y atención de enfermedades agudas en casos que el
                paciente tenga dificultad para trasladarse al consultorio ya sea
                por debilidad, dolores intensos, senilidad, vejez, casos en los
                que una enfermedad o dolencia pueda exacerbarse con el
                movimiento, necesidad de tratamiento de inmediato o de guardar
                reposo. En el caso de solicitud del paciente o familiares,
                también debe ser visitado. La visita domiciliaria para atender
                enfermedades agudas nos permite evaluar las condiciones del
                hogar y el apoyo familiar para decidir o no el ingreso
                domiciliario u hospitalario en los casos necesarios.
              </li>
              <li>
                Evaluación y tratamiento a pacientes recién egresados del
                hospital tras una intervención quirúrgica u otra situación. Esto
                posibilita el alta precoz y la incorporación del paciente a su
                medio habitual.
              </li>
              <li>
                Pacientes con enfermedades crónicas o discapacidades,
                seguimiento de su evolución, supervisión del tratamiento y
                valoración de las condiciones del hogar y apoyo familiar.
              </li>
              <li>
                Pacientes con enfermedades terminales, que pueden morir más
                tranquilos y rodeados de un ambiente de afecto en su casa,
                aunque no haya nada que hacer con respecto al resultado final de
                la enfermedad. En cuidarcasassalud consideramos que sí hay mucho
                que hacer, pues resulta insustituible el apoyo y afecto que se
                le da al paciente y a su familia, además del auxilio que se le
                proporciona en períodos de crisis.
              </li>
              <li>
                Necesidad de evaluación de las condiciones domiciliarias y
                visión de la familia. El hogar refleja los valores de la
                familia, y los pacientes expresan sus sentimientos con mayor
                facilidad en su propio ambiente.
              </li>
              <li>
                Debemos recordar que para las visitas al hogar, sobre todo en
                caso de ingresos, se requiere un mínimo de habilidades clínicas,
                apoyo tecnológico y medicamentos
              </li>
            </SpacedUL>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">En una buena visita al hogar:</Title>
            <ul>
              <li>Los profesionales cumplen con las visitas programadas.</li>
              <li>
                Los pacientes y la familia son tratados en forma ética y con
                debido respeto.
              </li>
              <li>Nunca se demuestra apuro o desinterés.</li>
              <li>
                No hay bromas de mal gusto ni observaciones que puedan resultar
                ofensivas o fuera de lugar. Siempre es importante obrar con (3)
                discreción, (3) prudencia y sentido común.
              </li>
              <li>
                El individuo y la familia son atentamente escuchados, evaluados
                y atendidos
              </li>
              <li>
                Los profesionales inspiran confianza, respeto y seguridad.
              </li>
              <li>
                Todo será explicado con sencillez y con el mayor detalle
                posible, a fin de que paciente y familia comprendan lo que está
                sucediendo y los procedimientos a realizar.
              </li>
              <li>
                Médico o profesional inspeccionarán aspectos vitales como:
                riesgo de accidentes, hábitos tóxicos, alimentación, dinámica
                familiar, higiene personal y del hogar, condiciones de vida,
                apoyo familiar al paciente, y realizarán las intervenciones
                necesarias para solucionar las deficiencias detectadas.
              </li>
              <li>
                Al final de la consulta, todos los aspectos habrán quedado
                cubiertos, el médico buscará aclarar todas las dudas y pactará
                la siguiente visita, en caso de ser necesaria.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Title fontSize="20px">Consideraciones finales.</Title>
          </Paragraph>
          <Paragraph>
            La visita y la atención médica en el hogar se han fortalecido con el
            transcurso de los años, ampliándose su justificación, objetivos y
            ventajas. Es conveniente conocer un grupo de orientaciones mínimas
            para su desarrollo exitoso. La visita médica al hogar es un pilar
            fundamental e insustituible del trabajo del Médico y la Enfermera de
            Familia.
          </Paragraph>
        </MainContent>
      </MainGrid>
    </>
  );
};

const SpacedUL = styled.ul`
  li {
    margin-bottom: 10px;
  }
`;

export default MedicoDomiciliario;
