import React from "react";

import {
  MainGrid,
  MainContent,
  Spacer,
} from "../../services/common";
import ProductCards from "../../services/components/productCard/ProductCards";

import SRAluminio from './silla-ruedas-aluminio.webp';
import SRAutotransporte from './silla-ruedas-autotransporte.webp';
import SRNeumatico from './silla-ruedas-neumatico.webp';
import SRPediatrica from './silla-ruedas-pediatrica.webp';
import SRPostura from './silla-ruedas-postura.webp';
import SRReforzada from './silla-ruedas-reforzada.webp';
import SRTransporte from './silla-ruedas-transporte.webp';

const items = [
    { img: SRAluminio, text: "Silla de ruedas de Autot. de aluminio" },
    { img: SRAutotransporte, text: "Silla de ruedas de Autotransporte" },
    { img: SRTransporte, text: "Silla de ruedas de Transporte" },
    { img: SRNeumatico, text: "Silla de ruedas de Autot. Neumático" },
    { img: SRPostura, text: "Silla de ruedas de Postura" },
    { img: SRPediatrica, text: "Silla de ruedas de Pediátrica" },
    { img: SRReforzada, text: "Silla de ruedas Reforzada" },
  ];
  const config = {
      cardWidht: "311px",
      cardHeight: "381px",
  };
  
  const SillasRuedas = () => {
    return (
      <>
        <MainGrid backgroundColor={({theme}) => theme.colors.defaultBackground}>
          <MainContent noPadding="true" backgroundColor={({theme}) => theme.colors.bright}>
            <Spacer height="40px" />
            <ProductCards items={items} config={config} />
            <Spacer height="40px" />
          </MainContent>
        </MainGrid>
      </>
    );
  };
  
  export default SillasRuedas;